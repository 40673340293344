.my-datafiles .files {
	background: #fff;
	border: 1px solid silver;
	border-radius: 4px;
	box-shadow: 0 2px 13px #00000030;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	width: 80%;
}

.my-datafiles .files .chip {
	display: flex;
	padding: 2px 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	width: fit-content;
	min-width: unset;
}