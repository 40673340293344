.search-across {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.search-across .search-container {
	display: flex;
	flex-direction: row;
	gap: 12px;
}
.search-across .search-container .actions {
	display: flex;
	flex-direction: column;
	gap: 12px;
}