.add-version {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	padding: 5% 10%;
	width: 100%;
}

.add-version .steps-container {
	background: #fff;
	border: 1px solid silver;
	border-radius: 4px;
	box-shadow: 0 2px 13px #00000030;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	width: 100%;
}

.add-version .steps-container .generate-template {
	width: fit-content;
	align-self: end;
}