.p-blockui-container {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 24px;
	width: 100%;
	padding: 24px;
	border-radius: 4px;
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.19);
	background: #FFFFFF;
	border: 1px solid #C0C0C0;
}

.p-blockui-container .p-progress-spinner {
	position: absolute;
	top: auto;
	left: auto;
}

.p-blockui-container .p-progress-spinner-circle{
	stroke: #000000 !important;
}

.p-blockui-container .content {
	visibility: hidden;
}