.footer {
	display: flex;
	background: #054769;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.footer .content {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.footer .content .upper {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	padding: 12px 12px 12px 0;
	border-bottom: 1px solid white;
}

.footer .content .upper img {
	width: 100px;
}

.footer .content .upper .icons {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.footer .content .upper .logos {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.footer .content .upper .logos .cgiar{
	height: 70px;
	width: 70px;
}
.footer .content .upper .icons i {
	color: #fff;
	font-size: 26px;
}

.footer .content .lower {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	padding: 12px 0;
}

.footer .content .lower a {
	color: rgb(165, 165, 165)
}

.footer .content .lower span {
	font-size: larger;
	color: rgb(220, 220, 220);
}

.footer .content .lower .privacy-terms {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
}

.footer .content .lower .privacy-terms .separator {
	width: 1px;
	height: 100%;
	background: white;
}

.footer .content .lower .privacy-terms .p-button {
	padding: 0;
}

.footer .content .lower .privacy-terms .p-button-label {
	font-weight: normal;
}

.privacy-terms-of-use {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.privacy-terms-of-use .title {
	font-weight: 500;
	font-size: 18px;
}

.privacy-terms-of-use .section-title {
	font-weight: 500;
	font-size: 20px;
}
