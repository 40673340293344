.edit-data {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	/*padding: 5% 10%;*/
	width: 100%;
}

.edit-data .users-table {
	width: 100%
}

.add-version .steps-container .users-table-table-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}

.edit-data .buttons-container {
	display: flex;
	align-items: start;
	justify-content: space-between;
	width: 100%;
}