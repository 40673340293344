.table-containers .dynamic-table .p-multiselect {
	max-width: 100%;
}

.table-containers .dynamic-table .column-header {
	font-size: 18px;
	color: #000000;
	text-transform: capitalize;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
	text-transform: capitalize;
}