.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.home .header {
	display: flex;
	width: 80%;
	padding: 24px 24px 0 24px;
}