.code-generator {
	display: flex;
	flex-direction: column;
	width: 100%;

	gap: 24px;
	padding: 24px;
	border-radius: 4px;
	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.19);
	background: #FFFFFF;
	border: 1px solid #C0C0C0;
}
.code-generator .code-snippet {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.code-generator .code-snippet .generated-code p {
	word-wrap: break-word
}

.code-generator .code-snippet .generated-code .p-button {
	position: absolute;
	right: 0;
}

.code-generator  .generated-code {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	gap: 24px;
	padding: 24px;
	border-radius: 4px;
	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.19);
	background: #FFFFFF;
	border: 1px solid #C0C0C0;
}

.code-generator .generated-code .generated-text {
	-webkit-user-select: none;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
/*.my-apis .code-generator .p-tabview {*/
/*	padding: 24px;*/
/*	border-radius: 4px;*/
/*	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.19);*/
/*	background: #FFFFFF;*/
/*	border: 1px solid #C0C0C0;*/
/*}*/