.landing {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 24px;
	gap: 24px;
	background-color: #156082;
	height:	100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	z-index: 10;
}

.landing .left {
	display: flex;
	flex-direction: column;
	width: 70%;
	gap: 24px;
	align-items: flex-start;
}

.landing .left .title {
	font-size: 32px;
	color: white;
	font-weight: bold;
}

.landing .left .description {
	font-size: 24px;
	color: white;
}

.landing img {
	width: 20%;
}