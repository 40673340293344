.my-apis {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	padding: 5% 10%;
	width: 100%;
}

.my-apis .my-apis-table {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	padding: 24px;
	border-radius: 4px;
	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.19);
	background: #FFFFFF;
	border: 1px solid #C0C0C0;
}

.my-apis .my-apis-table .actions {
	display: flex;
	flex-direction: row;
}

.my-apis .my-apis-table .p-datatable, .my-apis .my-apis-table .p-datatable-table, .my-apis .my-apis-table .p-datatable-wrapper {
	width: 100%;
}

.my-apis .my-apis-table .p-skeleton {
	display: flex;
	align-items: center;
	justify-content: center;
}

.my-apis .code-snippet-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}

.create-api-choose-datapool {
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 20px;
}

.create-api-choose-datapool .instructions {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.create-api-choose-datapool .instructions .title {
	font-weight: 500;
	font-size: 20px;
}

.create-api-choose-datapool .radio-buttons {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.create-api-choose-datapool .dropdown-and-button {
	display: flex;
	flex-direction: row;
	gap: 10px;
}